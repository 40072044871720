.meeting-room-view {
  display: flex;
  flex-direction: column;

  .choose-duration {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    overflow-x: scroll;


    .duration-container {
      display: flex;
      border-radius: 10px;
      border: 1px solid var(--light-grey);
      padding: 10px;
      margin-right: 10px;
      cursor: pointer;
      white-space: nowrap;
      font-family: monospace;
    }

    .active-duration {
      background: var(--light-grey);
      color: var(--white);
      font-weight: 900;
    }
  }

  .choose-time {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    overflow-x: scroll;

    @media (min-width: 1100px) {
      width: 100%;
      justify-content: flex-start;
      flex-wrap: wrap;
      overflow: visible;
    }

    .duration-container {
      cursor: pointer;
      display: flex;
      border-radius: 10px;
      padding: 10px;
      border: 1px solid var(--primary_color);
      margin-right: 10px;
      margin-bottom: 10px;
      span {
        font-family: monospace;
      }
    }

    .active-duration {
      background: var(--primary_color);
      color: var(--white);
    }
  }

  b {
    //background: var(--primary_color);
    background: var(--it-park-orange);
    color: var(--white);
    border-radius: 10px;
    white-space: nowrap;
    padding: 2px;
    padding-left: 5px;
    padding-right: 5px;
  }
}