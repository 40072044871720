.meeting-room-thumb {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin: 0px;
  margin-right: 10px;
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  min-width: 120px;
  height: 140px;
  cursor: pointer;
  overflow: hidden;

  @media (min-width: 1100px) {
    max-width: 200px;
  }

  img {
    z-index: 0;
    display: flex;
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .toner {
    transition: 400ms;
    display: flex;
    z-index: 1;
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background: linear-gradient(180deg, var(--secondary_color), transparent);
  }

  h3 {
    margin: 0;
    font-size: 16px;
    color: var(--white);
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 2;
  }

}

.active-thumb {
  outline: 4px solid var(--it-park-orange);
  border-radius: 10px;
  .toner {
    transition: 400ms;
    background: linear-gradient(180deg, var(--black), transparent);
  }
  //background: var(--light-grey);
}