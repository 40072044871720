.reservation {
  display: flex;
  background: #ededed;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  //box-shadow: 3px 3px 6px rgba(40,40,40,0.4);
  border-radius: 10px;
  overflow: hidden;
  padding: 0;
  max-height: 120px;
  margin-bottom: 10px;
  position: relative;
  .id {
    position: absolute;
    top: 0px;
    right: 10px;
    font-size: 10px;
    opacity: 0.4;
  }
  img {
    display: flex;
    height: 100%;
    max-width: 26%;
    object-fit: cover;
  }

  .content {
    width: 74%;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
    align-items: center;

    .column {
      width: 70%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-content: space-between;

      p {
        text-transform: uppercase;
        font-size: 19px;
        margin: 0;
      }
      p.time {
        font-size: 30.4px;
      }
    }

    .all-center {
      display: flex;
      flex-direction: row;
      align-content: center;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      p.time {
        font-size: 27.2px;
      }


    }

  }
}