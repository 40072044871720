* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

input {
  min-width: 200px;
  margin: 5px;
  font-size: 16px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid var(--primary_color);
}

button {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 10px;
  border: none;
  background: var(--primary_color);
  color: var(--white);
  min-width: 200px;
  padding: 10px;
}

.main-content {
  padding: 10px;
}


*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
*::-webkit-scrollbar-track {
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: var(--light-grey);
  border-radius: 10px;
}
