.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  background: linear-gradient(90deg, var(--grey), var(--primary_color), var(--light-grey));
  color: var(--white);
  padding: 10px;
  box-sizing: border-box;

  h1 {
    margin: 0;
    font-size: 16px;
  }
}