.meeting-room-column-view {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-right: 2px solid #858585;
  position: relative;
  img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    object-fit: cover;
    height: 100vh;
    filter: brightness(0.4) blur(5px);
  }
  h3 {
    z-index: 1;
    margin: 0;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 30px;
    color: #040404;
    backdrop-filter: blur(4px);
    background: rgba(200, 200, 200, 0.5);
  }
  p {
    max-width: 100%;
    overflow-x: scroll;
  }

  .duration-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid var(--light-grey);
    padding: 7px;
    margin-right: 10px;
    margin-left: 10px;
    cursor: pointer;
    white-space: nowrap;
    font-family: monospace;
    position: relative;
    z-index: 3;
    margin-top: 10px;
    color: var(--white);
    font-size: 18px;
    text-align: center;
  }
}