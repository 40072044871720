:root {
  --black: #050505;
  --white: #FFF;
  --bg_primary: #ededed;
  --primary_color: #858585;
  --secondary_color: #454545;
  --light-grey: #ababab;
  --grey: #959595;
  --it-park-orange: #ED763D;
  --red: #ff0000;
}