.meeting-rooms-index {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  width: 100%;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  padding-bottom: 7px;
  padding-top: 10px;
  padding-left: 7px;
}





